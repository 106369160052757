var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sourceForm"},[_c('div',{staticClass:"bottom-wrap"},[_c('search-data',{ref:"search",attrs:{"title":'数据分析名称：',"placeholder":'请输入名称',"search-url":_vm.getAnalysisList,"params":_vm.params,"otherFildeShowTitle":_vm.showTitle,"otherFildeShowAry":_vm.showAry,"isOtherFileds":true,"isShow":false},on:{"goNextEvent":_vm.goNext,"goNextAdd":_vm.goAdd,"del":_vm.deletAnaly}})],1),_c('div',[_c('v-dialog',{attrs:{"title":_vm.title,"width":"50%"},on:{"cancel":_vm.getInit,"confirm":_vm.submit},model:{value:(_vm.dialogFit),callback:function ($$v) {_vm.dialogFit=$$v},expression:"dialogFit"}},[_c('form-panel',{ref:"formPanel",staticClass:"form-panel",attrs:{"labelWidth":'120px',"form":_vm.form,"hasHeader":false,"label-position":'left'}},[_c('div',{staticClass:"title"},[_vm._v("基本信息")]),_c('el-form-item',{attrs:{"label":"名称","rules":[
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],"prop":"name"}},[_c('v-input',{attrs:{"placeholder":"请输入名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"title"},[_vm._v("选择模型")]),_c('el-form-item',{attrs:{"label":"模型名称","rules":[
            {
              required: true,
              message: '请选择模型名称',
              trigger: 'blur',
            },
          ],"prop":"value"}},[_c('el-select',{attrs:{"filterable":"","remote":"","clearable":true,"reserve-keyword":"","placeholder":"请输入关键词","remote-method":_vm.remoteMethod},on:{"change":_vm.getChange},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('div',{staticClass:"title"},[_vm._v("离线分析")]),_c('el-form-item',{attrs:{"label":"查询内容","rules":[
            {
              required: true,
              message: '请输入查询sql',
              trigger: 'blur',
            },
          ],"prop":"querySql"}},[_c('v-input',{staticClass:"item-input",attrs:{"clearable":"","type":"textarea","placeholder":"请输入查询sql","width":310,"maxlength":10000},model:{value:(_vm.form.querySql),callback:function ($$v) {_vm.$set(_vm.form, "querySql", $$v)},expression:"form.querySql"}})],1),_c('el-form-item',[_c('v-button',{attrs:{"text":"结果预览"},on:{"click":function($event){return _vm.getShow()}}})],1),_c('el-form-item',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tabLoad),expression:"tabLoad"}]},[(_vm.tableList.length > 0)?_c('v-table',{ref:"vTable",attrs:{"tableData":_vm.tableList,"isOperateColumn":false,"headers":_vm.headers}}):_vm._e()],1),(_vm.show)?_c('div',[_vm._v("预览结果为空")]):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }