<template>
  <div class="sourceForm">
    <div class="bottom-wrap">
      <search-data
        ref="search"
        :title="'数据分析名称：'"
        :placeholder="'请输入名称'"
        @goNextEvent="goNext"
        @goNextAdd="goAdd"
        :search-url="getAnalysisList"
        :params="params"
        :otherFildeShowTitle="showTitle"
        :otherFildeShowAry="showAry"
        :isOtherFileds="true"
        :isShow="false"
        @del="deletAnaly"
      ></search-data>
    </div>
    <div>
      <v-dialog
        :title="title"
        v-model="dialogFit"
        width="50%"
        @cancel="getInit"
        @confirm="submit"
      >
        <form-panel
          :labelWidth="'120px'"
          ref="formPanel"
          :form="form"
          :hasHeader="false"
          :label-position="'left'"
          class="form-panel"
        >
          <div class="title">基本信息</div>
          <el-form-item
            label="名称"
            :rules="[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ]"
            prop="name"
          >
            <v-input placeholder="请输入名称" v-model="form.name"></v-input>
          </el-form-item>
          <div class="title">选择模型</div>
          <el-form-item
            label="模型名称"
            :rules="[
              {
                required: true,
                message: '请选择模型名称',
                trigger: 'blur',
              },
            ]"
            prop="value"
          >
            <el-select
              v-model="form.value"
              filterable
              remote
              :clearable="true"
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              @change="getChange"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="title">离线分析</div>

          <el-form-item
            label="查询内容"
            :rules="[
              {
                required: true,
                message: '请输入查询sql',
                trigger: 'blur',
              },
            ]"
            prop="querySql"
          >
            <v-input
              clearable
              type="textarea"
              placeholder="请输入查询sql"
              :width="310"
              :maxlength="10000"
              class="item-input"
              v-model="form.querySql"
            ></v-input>
          </el-form-item>
          <el-form-item>
            <v-button text="结果预览" @click="getShow()"> </v-button>
          </el-form-item>
          <el-form-item v-loading="tabLoad">
            <v-table
              v-if="tableList.length > 0"
              :tableData="tableList"
              ref="vTable"
              :isOperateColumn="false"
              :headers="headers"
            >
            </v-table>
          </el-form-item>
          <div v-if="show">预览结果为空</div>
        </form-panel>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import searchData from "../components/search/index.vue";
import {
  getAnalysisList,
  getModelList,
  getShowList,
  getModelDeatil,
  addAnaly,
  deleteAnaly,
} from "./api";
export default {
  components: {
    searchData,
  },
  data() {
    return {
      tabLoad: false,
      title: "新建离线分析",
      value: "",
      getModelList,
      show: false,
      getShowList,
      showAry: ["relatedModelName", "querySql"],
      params: {
        curPage: 1,
        pageSize: 20,
        name: undefined,
        type: undefined,
      },
      showTitle: {
        relatedModelName: "关联模型",
        querySql: "分析内容",
      },
      getAnalysisList,
      dialogFit: false,
      dialogComputed: false,
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      options: [],
      activeName: "first",
      form: {
        name: "",
        querySql: "",
        datamodelId: "", //模型id
        datastoreId: "", //存储id
        value: "",
        id: "",
      },
      requestList: [],
    };
  },
  created() {
    this.remoteMethod();
  },
  computed: {
    tableList() {
      // 处理数据
      let newArr = [];
      this.requestList.forEach((item) => {
        let obj = [];
        item.forEach((items) => obj.push([items.field, items.value]));
        newArr.push(Object.fromEntries(obj));
      });
      return newArr;
    },
    headers() {
      let headerList = [];
      this.tableList.forEach((item) => {
        headerList = Object.keys(item);
      });
      const newHeaderList = [...new Set(headerList)];
      let data = [];
      newHeaderList.forEach((item) => {
        data.push({
          prop: item,
          label: item,
        });
      });
      return data;
    },
  },
  mounted() {
    this.$setBreadList("新建模型");
    this.$refs.search.getList(this.params);
  },
  methods: {
    getModel() {},
    //远程搜索
    remoteMethod(query) {
      if (query !== undefined) {
        query = query.replace(/\s*/g, "");
      }
      this.options = [];
      let params = {
        pageSize: 10,
        curPage: 1,
        name: query === "" ? undefined : query,
      };
      this.loading = true;
      this.$axios.get(`${getModelList}`, { params }).then((res) => {
        if (res.code === 200) {
          this.options = res.data.records;
          this.loading = false;
        }
      });
    },
    getShow() {
      this.requestList = [];
      const result = this.$refs.formPanel.validate();
      if (result === true) {
        this.$axios
          .post(`${getShowList}`, this.form, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            if (res.code == 200) {
              if (res.data.columns.length === 0) {
                this.$message.error("预览结果为空");
              }
              this.requestList = res.data.datas;
              this.tabLoad = false;
            } else {
              this.$message(res.message);
              this.tabLoad = false;
            }
          });
      }
    },
    getChange(value) {
      if (value !== "" && value !== undefined) {
        this.$axios.get(`${getModelDeatil}?id=${value}`).then((res) => {
          if (res.code === 200) {
            this.form.datamodelId = res.data.id;
            this.form.datastoreId = res.data.datastoreId;
          }
        });
      }
    },
    goNext(item) {
      console.log("item=====>", item);
      this.title = "编辑";
      this.form.name = item.name;
      this.form.querySql = item.querySql;
      this.form.value = parseInt(item.datamodelId);
      this.form.id = item.id;
      this.form.datamodelId = item.datamodelId;
      this.form.datastoreId = item.datastoreId;
      this.getChange(this.form.value);
      this.dialogFit = true;
      this.tabLoad = true;
      this.requestList = [];
      this.$nextTick(() => {
        this.getShow();
      });
    },
    submit() {
      const result = this.$refs.formPanel.validate();
      if (result === true) {
        console.log(this.form);
        this.$axios
          .post(`${addAnaly}`, this.form, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            if (res.code === 200) {
              this.params.curPage = 1;
              this.$refs.search.getList(this.params);
              this.$message.success("保存成功");
              this.dialogFit = false;
              this.getInit();
            } else {
              this.$message.success("保存失败");
            }
          });
      }
    },
    goAdd() {
      this.title = "新建离线分析";
      this.getInit();
      this.dialogFit = true;
    },
    getInit() {
      this.form.name = "";
      this.form.querySql = "";
      this.form.datamodelId = "";
      this.form.datastoreId = "";
      this.form.value = "";
      this.requestList = [];
      this.form.id = "";
      this.dialogFit = false;
      this.show = false;
    },
    deletAnaly(id) {
      this.$axios.post(`${deleteAnaly}?id=${id}`).then((res) => {
        if (res.code == 200) {
          this.params.curPage = 1;
          this.$refs.search.getList(this.params);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
.form-panel {
  padding: 0 30px;
}
.check-panel {
  display: flex;
}
.item-model {
  display: flex;
  justify-content: space-between;
}

.sourceForm {
  box-sizing: border-box;
  height: 100%;
  .bottom-wrap {
    padding: 30px 20px;
    height: calc(100% - 150px);
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .mt50 {
      margin-top: 30px;
    }
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 30px;
}
::v-deep .el-textarea__inner {
  height: 150px;
}
</style>
